const title = 'Free Cakes With Name Image Generator';
const description =
  'Generate birthday, anniversary, new year, Christmas cakes with name and greeting, instant download and share Free of any charge.';

const SEO = {
  title,
  description,
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://cakeiy.com',
    title,
    description,
    images: [
      {
        url: 'https://cakeiy.com/static/images/banner.png',
        alt: title,
        width: 1280,
        height: 720
      }
    ]
  },
  twitter: {
    handle: '@wishiycom',
    site: '@wishiycom',
    cardType: 'summary_large_image'
  }
};

export default SEO;